import Head from 'next/head';

const Versioning = () => {
  return (
    <Head>
      <meta name="version" content="1.1.4.579" />
      <meta name="build-date" content="2025-02-14T16:55:45Z" />
    </Head>
  );
};

export default Versioning;
